const SubscriptionSettingIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="white"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M2.55859 4.50488H20.5586V9.50488H22.5586V4.50488C22.5586 3.40488 21.6586 2.50488 20.5586 2.50488H2.55859C1.45859 2.50488 0.558594 3.40488 0.558594 4.50488V16.5049C0.558594 17.6049 1.45859 18.5049 2.55859 18.5049H11.5586V16.5049H2.55859V4.50488Z" />
      <path d="M14.5586 10.5049L8.55859 6.50488V14.5049L14.5586 10.5049Z" />
      <path d="M22.2686 16.9349C22.2986 16.6449 22.3086 16.3549 22.2786 16.0749L23.3486 15.2249C23.4486 15.1449 23.4686 15.0149 23.4086 14.9049L22.3786 13.1149C22.3186 13.0049 22.1886 12.9649 22.0686 13.0049L20.7886 13.5049C20.5586 13.3349 20.3086 13.1949 20.0386 13.0849L19.8386 11.7249C19.8186 11.5949 19.7186 11.5049 19.5886 11.5049H17.5186C17.3986 11.5049 17.2886 11.5949 17.2686 11.7149L17.0686 13.0749C16.8086 13.1849 16.5586 13.3349 16.3286 13.4949L15.0486 12.9949C14.9286 12.9449 14.7986 12.9949 14.7386 13.1049L13.7086 14.8949C13.6486 15.0049 13.6686 15.1349 13.7686 15.2149L14.8386 16.0749C14.8086 16.3649 14.7986 16.6549 14.8286 16.9349L13.7586 17.7849C13.6586 17.8649 13.6386 17.9949 13.6986 18.1049L14.7286 19.8949C14.7886 20.0049 14.9186 20.0449 15.0386 20.0049L16.3086 19.5049C16.5386 19.6749 16.7886 19.8149 17.0586 19.9249L17.2586 21.2849C17.2786 21.4049 17.3786 21.4949 17.5086 21.4949H19.5786C19.6986 21.4949 19.8086 21.4049 19.8286 21.2849L20.0286 19.9249C20.2886 19.8149 20.5386 19.6649 20.7686 19.5049L22.0486 20.0049C22.1686 20.0549 22.2986 20.0049 22.3586 19.8949L23.3886 18.1049C23.4486 17.9949 23.4286 17.8649 23.3286 17.7849L22.2686 16.9349ZM18.5586 18.0049C17.7286 18.0049 17.0586 17.3349 17.0586 16.5049C17.0586 15.6749 17.7286 15.0049 18.5586 15.0049C19.3886 15.0049 20.0586 15.6749 20.0586 16.5049C20.0586 17.3349 19.3886 18.0049 18.5586 18.0049Z" />
    </svg>
  );
};

export default SubscriptionSettingIcon;
